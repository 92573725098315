
@import "~bootstrap/scss/bootstrap";

.smooth-dnd-container {
    article > header > div {
        display: none;
    }
    article > div {
        font-size: 10px;
    }
}

.smooth-dnd-draggable-wrapper {
    > article > div {
        font-size: 10px;
    }
}